const imgList88 = {
	zhendong0: require('@assets/osImg/jkdp/zhendong0.gif'),
	zhendong1: require('@assets/osImg/jkdp/zhendong1.gif'),
	zhendong2: require('@assets/osImg/jkdp/zhendong2.gif'),
	zhendong3: require('@assets/osImg/jkdp/zhendong3.gif'),
	zhendong4: require('@assets/osImg/jkdp/zhendong-1.png'),
	wendu0: require('@assets/osImg/jkdp/wendu0.gif'),
	wendu1: require('@assets/osImg/jkdp/wendu1.gif'),
	wendu2: require('@assets/osImg/jkdp/wendu2.gif'),
	wendu3: require('@assets/osImg/jkdp/wendu3.gif'),
	wendu4: require('@assets/osImg/jkdp/wendu-1.png'),
	zhuansu0: require('@assets/osImg/jkdp/zhuansu0.gif'),
	zhuansu1: require('@assets/osImg/jkdp/zhuansu1.gif'),
	zhuansu2: require('@assets/osImg/jkdp/zhuansu2.gif'),
	zhuansu3: require('@assets/osImg/jkdp/zhuansu3.gif'),
	zhuansu4: require('@assets/osImg/jkdp/zhuansu-1.png'),
	youye0: require('@assets/osImg/jkdp/youye0.gif'),
	youye1: require('@assets/osImg/jkdp/youye1.gif'),
	youye2: require('@assets/osImg/jkdp/youye2.gif'),
	youye3: require('@assets/osImg/jkdp/youye3.gif'),
	youye4: require('@assets/osImg/jkdp/youye-1.png'),
	dianliu0: require('@assets/osImg/jkdp/dianliu0.gif'),
	dianliu1: require('@assets/osImg/jkdp/dianliu1.gif'),
	dianliu2: require('@assets/osImg/jkdp/dianliu2.gif'),
	dianliu3: require('@assets/osImg/jkdp/dianliu3.gif'),
	dianliu4: require('@assets/osImg/jkdp/dianliu-1.png'),
	shidu0: require('@assets/osImg/jkdp/shidu0.gif'),
	shidu1: require('@assets/osImg/jkdp/shidu1.gif'),
	shidu2: require('@assets/osImg/jkdp/shidu2.gif'),
	shidu3: require('@assets/osImg/jkdp/shidu3.gif'),
	shidu4: require('@assets/osImg/jkdp/shidu-1.png'),
}

function setTubiao(item, e) {
	switch (item.type) {
		case 1000:
			switch (item.alarm) {
				case -1:
					return e.zhendong4;
				case 0:
					return e.zhendong0;
				case 1:
					return e.zhendong1;
				case 2:
					return e.zhendong2;
				case 3:
					return e.zhendong3;
				default:
					return e.zhendong4;
			}
			break;
		case 1002:
			switch (item.alarm) {
				case -1:
					return e.wendu4;
				case 0:
					return e.wendu0;
				case 1:
					return e.wendu1;
				case 2:
					return e.wendu2;
				case 3:
					return e.wendu3;
			}
			break;
		case 1001:
			switch (item.alarm) {
				case -1:
					return e.zhuansu4
				case 0:
					return e.zhuansu0
				case 1:
					return e.zhuansu1
				case 2:
					return e.zhuansu2
				case 3:
					return e.zhuansu3
			}
			break;
		case 1003:
			switch (item.alarm) {
				case -1:
					return e.youye4
				case 0:
					return e.youye0
				case 1:
					return e.youye1
				case 2:
					return e.youye2
				case 3:
					return e.youye3
			}
			break;
		case 1004:
			switch (item.alarm) {
				case -1:
					return e.zhuansu4
				case 0:
					return e.zhuansu0
				case 1:
					return e.zhuansu1
				case 2:
					return e.zhuansu2
				case 3:
					return e.zhuansu3
			}
			break;
		case 1006:
			switch (item.alarm) {
				case -1:
					return e.shidu4;
				case 0:
					return e.shidu0;
				case 1:
					return e.shidu1;
				case 2:
					return e.shidu2;
				case 3:
					return e.shidu3;
			}
			break;
		case 1007:
			switch (item.alarm) {
				case -1:
					return e.zhendong4;
				case 0:
					return e.zhendong0;
				case 1:
					return e.zhendong1;
				case 2:
					return e.zhendong2;
				case 3:
					return e.zhendong3;
				default:
					return e.zhendong4;
			}
			break;


	}
}

function setTubiao2(item, num) {
	switch (item) {
		case 'vib':
			switch (num) {
				case -2:
					return imgList88.zhendong4;
				case 0:
					return imgList88.zhendong0;
				case 1:
					return imgList88.zhendong1;
				case 2:
					return imgList88.zhendong2;
				case 3:
					return imgList88.zhendong3;
			}
			break;
		case 'temp':
			switch (num) {
				case -2:
					return imgList88.wendu4;
				case 0:
					return imgList88.wendu0;
				case 1:
					return imgList88.wendu1;
				case 2:
					return imgList88.wendu2;
				case 3:
					return imgList88.wendu3;
			}
			break;
		case 'rpm':
			switch (num) {
				case -2:
					return imgList88.zhuansu4
				case 0:
					return imgList88.zhuansu0
				case 1:
					return imgList88.zhuansu1
				case 2:
					return imgList88.zhuansu2
				case 3:
					return imgList88.zhuansu3
			}
			break;
		case 'oil':
			switch (num) {
				case -2:
					return imgList88.youye4
				case 0:
					return imgList88.youye0
				case 1:
					return imgList88.youye1
				case 2:
					return imgList88.youye2
				case 3:
					return imgList88.youye3
			}
			break;
		case 'elec':
			switch (num) {
				case -2:
					return imgList88.dianliu4
				case 0:
					return imgList88.dianliu0
				case 1:
					return imgList88.dianliu1
				case 2:
					return imgList88.dianliu2
				case 3:
					return imgList88.dianliu3
			}
			break;


	}
}

export {
	imgList88,
	setTubiao,
	setTubiao2
}