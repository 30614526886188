<template>
	<div class="data" id="data" ref="data">
		<div class="data-title">
			<div class="title-left fl"></div>
			<div class="title-center fl">棒线材设备智能运维系统</div>
			<div class="title-right fr"></div>
		</div>
		<div class="data-content">
			<div class="top">
				<div class="top-left">
					<img class="img" src="~@assets/osImg/jkdp/bg_right.gif" alt="">
					<div class="title">
						<span class="dian"></span>
						<img v-if="isChejian" @click="backFn" style="width: 20px;height: 20px; cursor: pointer;"
							src="@/assets/osImg/jkdp/back.png" />
						<span style="vertical-align: middle;">报警结构图</span>
					</div>
					<div id="echarts_4" class="charts"></div>
				</div>
				<div class="top-center">
					<div class="tipBox">
						<div v-if="currentDanti == '' && !hasAlarm" class="tipBox_1">
							当前设备正常
						</div>
						<div v-if="currentDanti == '' && hasAlarm" class="tipBox_2">
							存在异常设备! 请及时处理 !
						</div>
					</div>
					<div class="boxBar">
						<div class="box" v-for="item in measList" :key="'bx' + item.id">
							<div class="box-titlebox">
								<img :src="Tubiao(item, imgList)" />
								<el-tooltip v-if="item.stat == 0" class="item" effect="light" content="采集数据异常!"
									placement="top-start">
									<i class="el-icon-warning-outline"
										style="margin-left:3px; font-size: 18px;color:#ff5500;cursor: help;"></i>
								</el-tooltip>
								<span :title="item.name">{{ item.name }}</span>
							</div>
							<div class="box-item" v-for="(e, b) in item.data" :key="'de' + b"
								@click="openTuPus(e, item, measList)">
								<div style="width: 45%;text-align: center">{{ e.name }}</div>
								<div style="width: 55%;">{{ e.num + e.unit }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="top-right">
					<img class="img" src="~@assets/osImg/jkdp/bg_right.gif" alt="">
					<div class="title">
						<span class="dian"></span>
						<span>报警概况</span>
					</div>
					<div id="echarts_7" class="charts"></div>
				</div>
			</div>
			<div class="middle">
				<iframe ref="threeDFrame" :src="ifUrl" name="topFrame" scrolling="No" noresize="noresize"
					frameborder="0" id="three" class="three" style="width: 98%;height: 98%;margin-left: 20px;"
					v-if="ifUrl != ''"></iframe>
			</div>
			<div class="danti-pop" :style="dantiPopStyle" style="z-index: 3000;">
				<div class="danti-pop-title">
					<span>{{ CurrentDantiParam.name }}</span>
				</div>
				<div class="danti-pop-context">
					<div v-for="(item, i) in CurrentDantiParam.args" :key="i">{{ item }}</div>
					<img :src="imgUrl" style="height: 100px;width: 100px;margin: 5px;" />
				</div>
			</div>
			<div class="bottom ">
				<div class="bottom-left">
					<img class="img" src="~@assets/osImg/jkdp/bg_right.gif" alt="">
					<div class="title">
						<span class="dian"></span>
						车间信号采集
					</div>
					<div id="echarts_6" class="charts"></div>
				</div>
				<div class="bottom-center" v-if="currentDanti == ''">
					<div class="title" style="margin-left:15px"> <span class="dian"></span>设备报警情况</div>
					<div id="echarts_3" class="charts"></div>
				</div>
				<div class="bottom-center" v-else>
					<div style="display: flex;width: 100%;">
						<el-tabs @tab-click="handleClick" v-model="activeTabName"
							style="margin: 20px 30px 0 50px;flex:1;width: 0;">
							<el-tab-pane :label="item.name" :name="item.name" v-for="(item, i) in measTabList"
								:key="i"></el-tab-pane>
						</el-tabs>
						<div style="padding: 30px 30px 0 10px;">
							<div v-if="currentDanti != '' && measType == '1000'">
								<img v-if="measDef != '加速度'" src="@assets/osImg/jkdp/jiasudu1.png" title="加速度"
									class="shopSignal"
									@click="changeMeasDef('加速度')" />
								<img v-if="measDef == '加速度'" src="@assets/osImg/jkdp/jiasudu2.png" title="加速度"
									class="shopSignal" />
								<img v-if="measDef != '速度'" src="@assets/osImg/jkdp/sudu1.png" title="速度"
									class="shopSignal"
									@click="changeMeasDef('速度')" />
								<img v-if="measDef == '速度'" src="@assets/osImg/jkdp/sudu2.png" title="速度"
									class="shopSignal" />
								<img v-if="measDef != '位移'" src="@assets/osImg/jkdp/weiyi1.png" title="位移"
									class="shopSignal"
									@click="changeMeasDef('位移')" />
								<img v-if="measDef == '位移'" src="@assets/osImg/jkdp/weiyi2.png" title="位移"
									class="shopSignal" />
							</div>
						</div>
					</div>
					<div id="echarts_8" class="charts"></div>
				</div>
				<div class="bottom-right">
					<img class="img" src="~@assets/osImg/jkdp/bg_right.gif" alt="">
					<div class="bottom-right-content">
						<div class="title">
							<span class="dian"></span>
							车间设备列表
						</div>
						<div class="right-top-content">
							<div class="right-top-list">
								<div class="right-top-item" style="width: 35%;">设备名称</div>
								<div class="right-top-item" style="width: 40%;">设备状态</div>
								<div class="right-top-item" style="width: 25%;">处理情况</div>
							</div>
							<div id="bs_scroll_content" ref="boxHeight" class="bs_scroll_content" style="overflow:auto">
								<div ref="box">
									<div :id="'single' + index" v-for="(item, index) in listData" :key="index"
										class="itemStyle" :class="index % 2 === 0 ? 'color2D96FF' : ''">
										<div class="itemStyle1" style="width: 35%;">{{ item.text }}</div>
										<div class="itemStyle1" style="width: 40%;">
											<img :src="Tubiao2('vib', item.vib)" title="振动" />
											<img :src="Tubiao2('temp', item.temp)" title="温度" />
											<img :src="Tubiao2('oil', item.oil)" title="油液" />
											<img :src="Tubiao2('elec', item.elec)" title="电流" />
											<img :src="Tubiao2('rpm', item.rpm)" title="转速" />
										</div>
										<div class="itemStyle1" style="width: 25%;">
											<!-- <img v-if="item.alarm != 0 " src="@assets/osImg/jkdp/yes.png" /> -->
											<img v-if="item.alarm != 0" src="@assets/osImg/jkdp/no.png" />
											<img v-if="item.alarm == 0" src="@assets/osImg/jkdp/noneDelay.png" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="btn-jkztbg" style="z-index: 10;" @click="
				() => {
					this.$message.warning('无权限，请联系管理员');
				}
			">
			设备评价
		</div>
		<div class="btn-jkztbg" style="z-index: 100;" v-hasPerm="'1694651803024625664'" @click="openView()">设备评价</div>
		<div class="fullScreenBtn" @click="fullScreen()">{{ !isFullScreen ? '全屏' : '退出' }}</div>
		<div class="Logo"><img :src="logo" v-if="logo" /></div>
		<div class="time">{{ currentTime }}</div>
		<chats ref="chats" :row="tbRow2" :rows="tbRows" @update-value="closeChat"></chats>
		<threeDchats ref="threeDchats" :row="tbRow"></threeDchats>
	</div>
</template>
<script>
	import chats from '../../monitoring/component/chats.vue';
	import {
		imgList88,
		setTubiao,
		setTubiao2
	} from '@assets/js/screen.js';
	import {
		beFull,
		exitFull,
		watchFull
	} from 'be-full';
	import configApi from '@config/configApi';
	import moment from 'moment';
	import caches from '@cache/cache.js';
	import threeDchats from '../../monitoring/component/threeDchats.vue';
	var myChart3,
		myChart4,
		myChart5,
		myChart6,
		myChart7,
		myChart8,
		myChart9,
		measInteval = null,
		tempInteval = null;
	const token = caches.token.get();
	export default {
		components: {
			chats,
			threeDchats
		},
		data() {
			return {
				ifUrl: '',
				unitUrlList: [],
				isFullScreen: false,
				listData: [], //数据
				totalHeight: 0, //内容的高度
				openAnim: true, //是否开启滚动效果 true 开  false关
				timer: 40, //滚动步数  值越大滚动越快
				rowup: {}, //设置动画效果
				option: {},
				t: null,
				lineId: '',
				typeId: '',
				url: '',
				timeInterval: null,
				currentTime: '',
				tbRow: null,
				tbRow2: null,
				tbRows: null,
				alarmEqus: [],
				isOpenAnimate: false,
				dantiUrl: '',
				currentDanti: '',
				dantiPopStyle: {},
				measList: [],
				measTabList: [],
				minIndex: 0,
				maxIndex: 6,
				measDef: '加速度',
				measId: '',
				measUrl: '',
				measType: '',
				// countList: [],
				isChejian: false,
				timeArr: [],
				hasAlarm: false,
				CurrentDantiParam: {},
				imgUrl: '',
				firstMeasList: [],
				secondMeasList: [],
				measIndex: 0,
				tempList: [],
				firstTempList: [],
				secondTempList: [],
				tempIndex: 0,
				winccType: '实际转速',
				activeTabName: '',
				entInfo: {},
				logo: '',
				showMyChart: true,
				showMyChart8: true,
				imgList: imgList88,
				errorType: false //当前请求3D整线的状态是否是错误
			};
		},
		computed: {},
		updated() {
			window.onmessage = event => {
				this.data = event.data.data;
				if (!this.data) {
					return;
				}

				switch (this.data.type) {
					case 3:
						//单体进图谱
						this.getChats(this.data.row, this.data.rows);
						break;
					case 4:
						this.minIndex = 0;
						this.maxIndex = 6;
						this.currentDanti = this.data.data;
						this.measId = '';
						this.firstMeasList = [];
						this.secondMeasList = [];
						this.measList = [];
						this.measIndex = 0;
						clearInterval(measInteval);
						measInteval = null;
						// clearInterval(tempInteval);
						// tempInteval = null;
						this.measUrl = '';
						this.getMeasList();

						this.getMeasTabList();
						$('.con-center').css('cursor', 'pointer');
						// this.dantiUrl =
						// 	'/html/three2.html?token=' +
						// 	token +
						// 	'&unitId=' +
						// 	this.data.data.unitId +
						// 	'&typeId=' +
						// 	this.data.data.typeId +
						// 	'&url=' +
						// 	this.baseURL +
						// 	'&imgUrl=' +
						// 	this.photoURL;
						// 	console.log(this.dantiUrl)
						break;
					case 5:
						if (this.currentDanti != '') {
							this.currentDanti = '';
							this.firstMeasList = [];
							this.secondMeasList = [];
							this.measList = [];
							this.measIndex = 0;
							this.measId = '';
							this.measUrl = '';
							clearInterval(measInteval);
							measInteval = null;
							this.getMonthAlarmData();
						}
						break;
					case 6:
						this.dantiPopStyle = {
							display: 'none'
						};
						this.$refs.threeDchats.dialogVisible = true;
						this.$refs.threeDchats.fullscreen = true;
						this.tbRow = Object.assign(this.data.data);
						let fileUrl = ''
						if (this.tbRow.isType==1) {
							fileUrl = '/html/index.html'
						} else {
							fileUrl = '/html/indexOld.html'
						}
						this.tbRow.url =
							fileUrl+'?token=' +
							token +
							'&unitId=' +
							this.data.data.unitId +
							'&typeId=' +
							this.data.data.typeId +
							'&url=' +
							this.baseURL +
							'&imgUrl=' +
							this.photoURL +
							'&canBack=' +
							'2';
						break;
					case 7:
						if (this.data.data.args != '') {
							this.CurrentDantiParam = JSON.parse(this.data.data.args);
							this.imgUrl = configApi.photoURL + this.CurrentDantiParam.img;
						} else {
							this.CurrentDantiParam = {};
						}
						this.CurrentDantiParam.name = this.data.data.name;
						this.dantiPopStyle = {
							left: this.data.mouse.x - 70 + 'px',
							bottom: $('.middle').innerHeight() + 10 - this.data.mouse.y + 'px',
							display: 'block'
						};
						break;
					case 8:
						this.CurrentDantiParam = {};
						this.imgUrl = '';
						this.dantiPopStyle = {
							display: 'none'
						};
						break;
					case 9:
						this.dantiPopStyle = {
							display: 'none'
						};
						break;
					case 1111:
						let routeData = this.$router.resolve({
							name: 'shopDetails',
							query: {
								id: this.data.goodsId,
								skuId: this.data.goodsSkuId
							}
						});
						window.open(routeData.href, '_blank');
						break;
					case 2222:
						let routeData2 = this.$router.resolve({
							name: 'leaseDetails',
							query: {
								id: this.data.rentId,
								skuId: this.data.rentSkuId
							}
						});
						window.open(routeData2.href, '_blank');
						break;
					case 3333:
						let routeData3 = this.$router.resolve({
							name: 'diagnosisExpert'
						});
						window.open(routeData3.href, '_blank');
						break;
				}
			};
		},
		mounted() {
			this.getDynamic();
			this.getEntInfo();
			this.getLineID();
			this.getDatas();
			// this.t = setInterval(this.getDatas, 8000);
			// this.timeInterval = setInterval(() => {
			// 	this.currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
			// }, 1000);
			window.addEventListener('keydown', function(event) {
				//禁掉F11的全屏的默认事件,不会禁止F11的退出全屏
				const e = event || window.event;
				if (e && e.keyCode === 122) {
					e.preventDefault();
				}
			});
			let that = this;
			window.onresize = function() {
				if (!that.checkFull()) {
					// 退出全屏后要执行的动作
					if (that.isFullScreen) {
						that.isFullScreen = false;
					}
				}
			};
			document.addEventListener('fullscreenchange', this.handleFullScreenChange);
		},
		beforeDestroy() {
			window.removeEventListener('keydown', () => {}, true);
			clearInterval(this.t);
			clearInterval(this.timeInterval);
			document.removeEventListener('fullscreenchange', this.handleFullScreenChange);
		},
		methods: {
			Tubiao(item, e) {
				return setTubiao(item, e);
			},
			Tubiao2(item, e) {
				return setTubiao2(item, e);
			},
			//获取企业资料
			getEntInfo() {
				this.$get('/backend-api/sys/ent/get').then(res => {
					if (res.code == 1000) {
						this.entInfo = res.data;
						if (this.entInfo&&this.entInfo.logo) {
							this.logo = configApi.photoURL + this.entInfo.logo;
						}
					}
				});
			},
			//时间选择
			timeChange() {
				this.getEquipList();
			},
			//报警结构图返回按钮
			backToChang() {
				this.getTimingAlarm(true);
			},
			checkFull() {
				//判断浏览器是否处于全屏状态 （需要考虑兼容问题）
				//火狐浏览器
				var isFull =
					document.mozFullScreen ||
					document.fullScreen ||
					//谷歌浏览器及Webkit内核浏览器
					document.webkitIsFullScreen ||
					document.webkitRequestFullScreen ||
					document.mozRequestFullScreen ||
					document.msFullscreenEnabled;
				if (isFull === undefined) isFull = false;
				return isFull;
			},
			handleClick(tab, event) {
				let index = tab.index;
				let item = this.measTabList[index];
				this.winccType = item.type2;
				switch (item.type) {
					case 1000:
						this.measUrl = '/backend-api/web/spec/vib/getTrendByMeasDef';
						break;
					case 1001:
						this.measUrl = '/backend-api/web/spec/rpm/screen';
						break;
					case 1002:
						this.measUrl = '/backend-api/web/spec/temp/screen';
						break;
					case 1003:
						this.measUrl = '/backend-api/web/spec/oil/screen';
						break;
					case 1004:
						this.measUrl = '/backend-api/web/spec/rpm/screen';
						break;
				}
				this.measType = item.type;
				this.measId = item.id;
				this.getTupu2({
					type: item.type2
				});
			},

			changeMeasDef(meas_def) {
				this.measDef = meas_def;
				this.getTupu2();
			},
			getTupu2(obj) {
				let param = {
					meas_id: this.measId,
					meas_def: this.measDef
				};
				if (obj) {
					param = Object.assign(param, obj);
				}
				this.$get(this.measUrl, param).then(res => {
					if (res.code == 1000) {
						if (res.data.xData.length > 0) {
							this.showMyChart8 = false;
						} else {
							this.showMyChart8 = true;
						}
						this.echarts_8(res.data);
					} else {
						this.loading = false;
					}
				});
			},
			//打开图谱
			openTuPus(e, item, MeasList) {
				this.dantiPopStyle = {
					display: 'none'
				};
				if (e.meas_id == '') {
					e.meas_id = item.id;
				}
				this.getChats(e, MeasList);
			},
			// 打开图表弹框
			getChats(j, rows) {
				this.$refs.chats.dialogVisible = true;
				this.$refs.chats.fullscreen = true;
				this.tbRow2 = j;
				this.tbRows = rows;
			},
			//关闭图表页面
			closeChat(newValue) {
			      this.tbRow2 = null
			    },
			dantiHover(e, item) {
				$(e.currentTarget)
					.children('.danti-background')
					.css('display', 'none');
				$(e.currentTarget)
					.children('.danti-background.actived')
					.css('display', 'block');
				$(e.currentTarget)
					.children('.danti-pop')
					.css('display', 'block');
			},
			hoverInThreeD(item) {
				this.$refs.threeDFrame.contentWindow.postMessage(item, '*');
			},
			leaveDanti(item) {
				this.$refs.threeDFrame.contentWindow.postMessage({
						type: 2
					},
					'*'
				);
			},
			dantiLeave(e, item) {
				$(e.currentTarget)
					.children('.danti-background')
					.css('display', 'block');
				$(e.currentTarget)
					.children('.danti-background.actived')
					.css('display', 'none');
				$('.danti-pop').css('display', 'none');
			},
			dantiClick(item) {
				this.$refs.threeDchats.dialogVisible = true;
				this.$refs.threeDchats.fullscreen = true;
				//进入单体
				this.url =
					'/html/index.html?token=' +
					token +
					'&unitId=' +
					item.unitId +
					'&typeId=' +
					item.typeId +
					'&url=' +
					this.baseURL +
					'&imgUrl=' +
					this.photoURL +
					'&canBack=' +
					'2';
			},
			getDatas() {
				this.getTimingAlarm(false);
				this.getEquipList();
				// this.getTimingAlarmEqu();
				this.getSignGet();
				this.getAllEquipment();
				if (this.currentDanti) {
					// this.getMeasList();
				} else {
					this.getMonthAlarmData();
				}
				if (this.errorType) {
					this.getLineID();
				}
			},
			//全屏
			fullScreen() {
				this.isFullScreen = !this.isFullScreen;
				let element = this.$refs.data;
				if (this.isFullScreen) {
					if (element.requestFullscreen) {
						element.requestFullscreen();
					} else if (element.mozRequestFullScreen) {
						element.mozRequestFullScreen();
					} else if (element.webkitRequestFullscreen) {
						element.webkitRequestFullscreen();
					} else if (element.msRequestFullscreen) {
						element.msRequestFullscreen();
					}
				} else {
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.mozExitFullScreen) {
						document.mozExitFullScreen();
					} else if (document.webkitExitFullscreen) {
						document.webkitExitFullscreen();
					}
				}
			},
			//退出全屏
			handleFullScreenChange() {
				if (!document.fullscreenElement) {
					// 在这里执行退出全屏后的逻辑
					this.isFullScreen = false;
				}
			},
			// 跳转页面
			openView() {
				$('.topMenu .el-menu-item').eq(1).trigger('click');
			},
			getLineID() {
				this.$get('backend-api/web/monitor/lineIds')
					.then(res => {
						if (res.code == 1000) {
							this.errorType = false;
							if (res.data != '') {
								this.lineId = res.data.line_id;
								this.typeId = res.data.pc_id;
								// this.get3DData(res.data);
								this.ifUrl =
									'/html/three.html?token=' +
									token +
									'&lineId=' +
									this.lineId +
									'&typeId=' +
									this.typeId +
									'&url=' +
									this.baseURL +
									'&imgUrl=' +
									this.photoURL;
							}
						} else {
							this.errorType = true;
						}
					})
					.catch(err => {
						this.errorType = true;
					});
			},
			// 实时动态
			getDynamic() {
				// 这里也可以请求后台数据
				this.$nextTick(() => {
					this.totalHeight = '-' + this.$refs.box.offsetHeight + 'px';
					const boxHeight = this.$refs.box.offsetHeight; //内容高度
					this.handleStpe(boxHeight);
					this.addKeyFrames(this.totalHeight);
				});
			},
			// 设置滚动速度，动画效果
			handleStpe(boxHeight) {
				this.rowup = {
					' -webkit-animation': '70s rowup linear infinite',
					animation: `${Math.floor((boxHeight * 1000) / this.timer)}ms rowup linear infinite`,
					position: 'relative'
				};
			},
			// 跑马灯
			addKeyFrames(y) {
				const style = document.createElement('style');
				style.type = 'text/css';
				const keyFrames = `\
			@-webkit-keyframes rowup {\
			    0% {\
			        -webkit-transform: translate3d(0, 0, 0);\
			        transform: translate3d(0, 0, 0);\
			    }\
			    100% {\
			        -webkit-transform: translate3d(0, A_DYNAMIC_VALUE, 0);\
			        transform: translate3d(0, A_DYNAMIC_VALUE, 0);\
			    }\
			}`;
				style.innerHTML = keyFrames.replace(/A_DYNAMIC_VALUE/g, y);
				document.getElementsByTagName('head')[0].appendChild(style);
			},
			// 停止动画
			Stop() {
				this.openAnim = !this.openAnim;
			},
			// 开始动画
			Up() {
				this.openAnim = true;
			},
			//获取设备列表
			getEquipList() {
				let param = null;
				if (this.timeArr.length == 1) {
					param = {
						begin_time: this.timeArr[0]
					};
				} else if (this.timeArr.length == 2) {
					param = {
						begin_time: this.timeArr[0],
						end_time: this.timeArr[1]
					};
				}
				this.$get('/backend-api/web/monitor/unit', param).then(res => {
					if (res.code == 1000) {
						this.listData = res.data;
						if (!this.isOpenAnimate) {
							this.getDynamic();
							this.isOpenAnimate = true;
						}
					} else {
						// this.$message.error(res.msg);
					}
				});
			},
			//获取30天报警情况
			getMonthAlarmData() {
				// /backend-api/alarm/unit/alarm/trend
				// /backend-api/web/monitor/state'

				this.$get('/backend-api/alarm/unit/alarm/trend?type=1').then(res => {
					if (res.code == 1000) {
						if (res.data.xData.length > 0) {
							this.showMyChart = false;
						} else {
							this.showMyChart = true;
						}
						this.echarts_3(res.data);
					}
				});
			},
			backFn() {
				this.isChejian = !this.isChejian;
				this.getTimingAlarm(false)
			},
			//获取实时预警情况
			getTimingAlarm(isClick) {
				let param = null;
				if (isClick) {
					if (!this.isChejian) {
						param = {
							type: 10
						};
					}
				} else {
					if (this.isChejian) {
						param = {
							type: 10
						};
					}
				}
				this.$get('/backend-api/web/monitor/line', param).then(res => {
					if (res.code == 1000) {
						this.echarts_4(res.data);
						if (isClick) {
							this.isChejian = !this.isChejian;
						}
					} else {
						// this.$message.error(res.msg);
					}
				});
			},
			//获取信号采集
			getSignGet() {
				this.$get('/backend-api/web/monitor/signal', null).then(res => {
					if (res.code == 1000) {
						this.echarts_6(res.data);
					} else {
						// this.$message.error(res.msg);
					}
				});
			},
			//获取报警概况
			getAllEquipment() {
				this.$get('/backend-api/web/monitor/alarm/ent', null).then(res => {
					if (res.code == 1000) {
						if (res.data[1].value == 0 && res.data[2].value == 0 && res.data[3].value == 0) {
							this.hasAlarm = false;
						} else {
							this.hasAlarm = true;
						}
						this.echarts_7(res.data);
					} else {
						// this.$message.error(res.msg);
					}
				});
			},
			//请求测点数据
			getMeasList() {
				let _this = this;
				this.$get('/backend-api/web/line/v2/meas/screen', {
					unit_id: this.currentDanti.unitId
				}).then(res => {
					if (res.code != 1000) {
						return;
					}
					this.measList = res.data;
					if (measInteval == null) {
						measInteval = setInterval(() => {
							_this.getMeasList();
						}, 7000);
					}
				});
			},

			//获取下部单体测点
			getMeasTabList() {
				this.$get('/backend-api/web/line/listMeas', {
					unit_id: this.currentDanti.unitId
				}).then(res => {
					if (res.code == 1000) {
						this.measTabList = res.data;
						this.winccType = this.measTabList[0].type2;
						let first = this.measTabList[0];
						this.activeTabName = first.name;
						//默认显示第一个的图谱
						if (this.measUrl == '') {
							if (first.type == 1000) {
								this.measUrl = '/backend-api/web/spec/vib/getTrendByMeasDef';
							} else if (first.type == 1001) {
								this.measUrl = '/backend-api/web/spec/rpm/screen';
							} else if (first.type == 1002) {
								this.measUrl = '/backend-api/web/spec/temp/screen';
							} else if (first.type == 1003) {
								this.measUrl = '/backend-api/web/spec/oil/screen';
							} else if (first.type == 1004) {
								this.measUrl = '/backend-api/web/spec/rpm/screen';
							}
							this.measType = first.type;
							this.measId = first.id;
						}
						this.getTupu2({
							type: first.type2
						});
					} else {
						// this.$message.error(res.msg);
					}
				});
			},

			echarts_3(data) {
				// 基于准备好的dom，初始化echarts实例
				if (myChart3 != null && myChart3 != '' && myChart3 != undefined) {
					myChart3.dispose(); //销毁
				}
				let option = {
					tooltip: {
						trigger: 'axis'
					},
					//运用echart中的title
					title: {
						show: this.showMyChart, //show 可以在上面顶一个一个 let show = null;
							color: '#fff',
							fontSize: 14,
							fontWeight: 100,
						text: '暂无数据', //这个你可以定义一个变量，也可以直接写死'暂无数据'
						left: 'center',
						top: 'center'
					},
					legend: {
						top: '1%',
						right: '3%',
						textStyle: {
							color: '#ffffff'
						},
						selectedMode: false //取消图例上的点击事件
					},
					grid: {
						left: '5%',
						right: '7%',
						top: '15%',
						bottom: '10%',
						containLabel: true
					},
					toolbox: {
						show: false,
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: false
							},
							magicType: {
								show: true,
								type: ['line', 'bar', 'stack', 'tiled']
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},

					calculable: true,
					xAxis: [{
						type: 'category',

						axisTick: {
							show: false
						},

						boundaryGap: false,
						axisLabel: {
							color: '#ccc',
							fontSize: '12',
							lineStyle: {
								color: '#2c3459'
							}
						},
						data: data.xData,
						name: '(日期)',
						splitLine: {
							show: true,
							lineStyle: {
								color: '#384049',
								type: 'dashed'
							}
						},
					}],
					yAxis: {
						minInterval: 1,
						type: 'value',
						axisLabel: {
							color: '#ccc',
							fontSize: '12'
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#384049'
							},
							onZero: false
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: '#384049',
								type: 'dashed'
							}
						},
						name: '(设备数)'
					},
					dataZoom: [{
							show: false
						},
						{
							type: 'inside',
							show: true,
							height: 15,
							start: 1,
							end: 35
						}
					],
					series: [{
							name: '报警设备数',
							type: 'line',
							symbolSize: 10,
							symbol: 'circle',
							itemStyle: {
									color: '#52C41AFF',
							},
							lineStyle: {
								width: 1.5, // 0.1的线条是非常细的了
								color: '#52C41AFF'
							},
							label: {
								show: false,
								position: 'top',
									color: '#52C41AFF'
							},
							smooth: true,
							data: data.yData
						}
						// B620E0FF
						// {
						// 	name: '已处理',
						// 	type: 'line',
						// 	symbolSize: 10,
						// 	symbol: 'circle',
						// itemStyle: {
						// 	color: '#52C41AFF',
						// },
						// lineStyle: {
						// 	width: 1.5, // 0.1的线条是非常细的了
						// 	color: '#52C41AFF'
						// },
						// 	smooth: true,
						// 	data: data.yData2,
						// 	label: {
						// 		show: false,
						// 		position: 'top',
						// 		color: '#52C41AFF'
						// 	}
						// }
					]
				};
				myChart3 = this.$echarts.init(document.getElementById('echarts_3'));
				// 使用刚指定的配置项和数据显示图表。
				myChart3.clear();
				myChart3.setOption(option, true);
				window.addEventListener('resize', function() {
					myChart3.resize();
				});
			},
			//实时预警情况
			echarts_4(data) {
				let offset = 0;
				// 基于准备好的dom，初始化echarts实例
				if (myChart4 != null && myChart4 != '' && myChart4 != undefined) {
					myChart4.dispose(); //销毁
				}
				myChart4 = this.$echarts.init(document.getElementById('echarts_4'));

				let indicator = [];
				let value = new Array(4);
				for (let i = 0; i < value.length; i++) {
					value[i] = new Array(data.length);
				}
				for (let i in data) {
					let item = data[i];
					indicator.push({
						name: item.text,
						max: 4,
						min: 0,
						color: item.act == 1 ? '#ffffff' : '#a7a7a7'
					});
					for (let j in item.alarm) {
						value[item.alarm[j]][i] = item.alarm[j];
					}
				}
				let option = {
					radar: [{
						indicator: indicator,
						axisName: {
							fontSize: '13',
							color: '#FFFFFF',
							lineHeight: 20,
							formatter: function(text) {
								text = text.replace(/\S{5}/g, function(match) {
									return match + '\n';
								});
								return text;
							}
						},
						triggerEvent: true,
						axisNameGap: 10,
						splitNumber: 4,
						splitArea: {
							areaStyle: {
								color: ['#102506', '#614409', '#582300', '#4E0B0B']
							}
						},
						label: {
							show: true,
							color: '#FFFFFF'
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#636363'
							}
						},
						splitLine: {
							show: false
						},
						center: ['50%', '50%'],
						radius: 60,
						axisLabel: {
							show: false,
							color: '#FFFFFF',
							fontSize: 12,
							formatter: function(value, index) {
								offset++;
								if (offset < 4) {
									if (value === 2) {
										return ['{lhStyle|危险}', '{pjStyle|报警}', '{jdStyle|警告}',
											'{jcStyle|正常}'
										].join('\n');
									}
								}
							},
							rich: {
								jcStyle: {
									color: '#ffffff',
									fontSize: 12,
									width: 200,
									height: 30,
									align: 'right'
								},
								jdStyle: {
									color: '#ffffff',
									fontSize: 12,
									width: 200,
									height: 30,
									align: 'right'
									// padding: [5, 10],
								},
								pjStyle: {
									color: '#ffffff',
									fontSize: 12,
									width: 200,
									height: 30,
									align: 'right'
									// padding: [5, 20],
								},
								lhStyle: {
									color: '#ffffff',
									fontSize: 12,
									width: 200,
									height: 30,
									align: 'right'
									// padding: [5, 30],
								}
							}
						}
					}],
					series: [{
						type: 'radar',
						symbolSize: 8,
						data: [{
								value: value[1],
								name: '',
								lineStyle: {
									width: 0
								},
								itemStyle: {
									borderWidth: 6,
									color: '#FFDC34'
								}
							},
							{
								value: value[2],
								name: '',
								lineStyle: {
									width: 0
								},
								itemStyle: {
									borderWidth: 6,
									color: '#E26617'
								}
							},
							{
								value: value[3],
								name: '',
								lineStyle: {
									width: 0
								},
								itemStyle: {
									borderWidth: 6,
									color: '#D00606'
								}
							},
							{
								value: value[0],
								name: '',
								lineStyle: {
									width: 0
								},
								itemStyle: {
									borderWidth: 6,
									color: '#52C41A'
								}
							}
						]
					}]
				};

				// 使用刚指定的配置项和数据显示图表。
				myChart4.clear();
				myChart4.setOption(option, true);
				myChart4.off('click');
				myChart4.on('click', params => {
					if (params.name.indexOf('轧钢') != -1) {
						this.getTimingAlarm(true);
					}
				});
				window.addEventListener('resize', () => {
					myChart4.resize();
				});
			},

			//信号采集
			echarts_6(data) {
				// 基于准备好的dom，初始化echarts实例
				if (myChart6 != null && myChart6 != '' && myChart6 != undefined) {
					myChart6.dispose(); //销毁
				}
				myChart6 = this.$echarts.init(document.getElementById('echarts_6'));

				let xData = [];
				let data1 = [];
				let data2 = [];
				for (let item of data) {
					xData.push(item.text);
					data1.push(item.actn);
					data2.push(item.test);
				}
				let option = {
					// tooltip: {
					// 	show: 'true',
					// 	trigger: 'item',
					// 	backgroundColor: 'rgba(0,0,0,0.4)', // 背景
					// 	padding: [8, 10], //内边距
					// 	formatter: function(params) {
					// 		if (params.seriesName != '') {
					// 			return params.name + ' ：  ' + params.value ;
					// 		}
					// 	}
					// },
					legend: {
						// orient: "vertical",
						right: '10%',
						textStyle: {
							color: '#ffffff'
						},
						// data: ['运行中', '调试中(准备)']
						data: ['运行中']
					},
					grid: {
						borderWidth: 0,
						top: 50,
						bottom: 60,
						left: 55,
						right: 55,
						color: '#fff'
					},
					xAxis: [{
							type: 'category',
							name: '信号',
							nameTextStyle: {
								color: '#ffffff'
							},
							axisTick: {
								show: false
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: 'rgba(160,160,160,0.3)'
								}
							},
							axisLabel: {
								inside: false,
									color: '#bac0c0',
									fontWeight: 'normal',
									fontSize: '12'
							},
							data: xData
						},
						{
							type: 'category',
							axisLine: {
								show: false
							},
							axisTick: {
								show: false
							},
							axisLabel: {
								show: false
							},
							splitArea: {
								show: false
							},
							splitLine: {
								show: false
							},
							data: xData
						}
					],
					yAxis: {
						type: 'value',
						name: '点位',
						nameTextStyle: {
							color: '#ffffff'
						},
						axisTick: {
							show: false

						},
						axisLine: {
							show: true,
							lineStyle: {
								color: 'rgba(160,160,160,0.3)'
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: 'rgba(160,160,160,0.3)',
								type: 'dashed'
							}
						},
						axisLabel: {
								color: '#bac0c0',
								fontWeight: 'normal',
								fontSize: '12',
							formatter: '{value}'
						}
					},
					series: [{
							name: '运行中',
								type: 'bar',
								label: {
									formatter: function(value) {
										return value.value ? value.value : '未显示';
									},
									show: true,
									position: 'top',
									fontSize: '12',
									color: '#fff'
								},
								itemStyle: {
									color: '#2CCCD2',
									borderWidth: 0
								},
								zlevel: 2,
								barWidth: '20%',
								data: data1
							},
						// {
						// 	name: '调试中(准备)',
						// 	type: 'bar',
						//label: {
						// 				formatter: function(value) {
						// 					return value.value;
						// 				},
						// 				show: true,
						// 				position: 'top',
						// 				fontSize: '12',
									// color: '#fff'
						// 			},
						// 	itemStyle: {
						// 			
						// 			color: '#0F6669',
						// 			// barBorderRadius: 50,
						// 			borderWidth: 0
						// 	},
						// 	zlevel: 2,
						// 	barWidth: '20%',
						// 	data: data2
						// }
					]
				};
				// 使用刚指定的配置项和数据显示图表。
				myChart6.clear();
				myChart6.setOption(option, true);
				window.addEventListener('resize', function() {
					myChart6.resize();
				});
			},
			//全厂设备
			echarts_7(data) {
				// 基于准备好的dom，初始化echarts实例
				if (myChart7 != null && myChart7 != '' && myChart7 != undefined) {
					myChart7.dispose(); //销毁
				}
				myChart7 = this.$echarts.init(document.getElementById('echarts_7'));
				let option = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						orient: 'vertical',
						left: '10%',
						bottom: '10%',
						itemWidth: 10,
						itemHeight: 10,
						formatter: function(name) {
							let value = '';
							for (let item of data) {
								if (item.name == name) {
									value = item.value;
								}
							}
							return name + ' ' + value;
						},
						textStyle: {
							color: '#ffffff'
						}
					},
					series: [{
						type: 'pie',
						radius: ['40%', '70%'],
						center: ['65%', '50%'],
						avoidLabelOverlap: false,
						color: ['#52C41A', '#D89614', '#E26617', '#D00606'],
						label: {
							show: false,
							position: 'center',
							formatter: function(data) {
								// 设置圆饼图中间文字排版
								return '{name|' + data.name + '}' + '\n{value|' + data.value + '}';
							},
							rich: {
								name: {
									fontSize: 12,
									color: '#FFFFFFD9'
								},
								value: {
									fontSize: 20,
									color: '#FFFFFFFF',
									lineHeight: 30
								}
							},
							Emphasis: {
								show: true, //文字至于中间时，这里需为true
								//设置文字样式
								fontSize: '18',
								color: '#ffffff'
							}
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '40',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: data
					}]
				};
				// 使用刚指定的配置项和数据显示图表。
				myChart7.clear();
				myChart7.setOption(option, true);
				window.addEventListener('resize', function() {
					myChart7.resize();
				});
			},
			//单体趋势/温度图
			echarts_8(data) {
				// 基于准备好的dom，初始化echarts实例
				if (myChart8 != null && myChart8 != '' && myChart8 != undefined) {
					myChart8.dispose(); //销毁
				}
				myChart8 = this.$echarts.init(document.getElementById('echarts_8'));
				let option;
				if (this.measType == 1000) {
					option = {
						tooltip: {
							trigger: 'axis',
							confine: true, // 加入这一句话
							backgroundColor: 'rgba(34, 34, 34, 0.5)',
							borderWidth: 0,
							color: '#ffffff',
							position: function(pos, params, el, elRect, size) {
								let obj = {
									top: 10
								};
								obj['left'] = pos[0] + 10;
								obj['top'] = pos[1] - 60;
								return obj;
							},
							formatter: param => {
								param = param[0];
								return [data.tooltip.x + '：' + param.name + '<br/>', data.tooltip.y + '：' + param
									.value + data.unit.y
								].join('');
							}
						},
						//运用echart中的title
						title: {
							show: this.showMyChart8, //show 可以在上面顶一个一个 let show = null;
							color: '#fff',
							fontSize: 14,
							fontWeight: 100,
							text: '暂无数据', //这个你可以定义一个变量，也可以直接写死'暂无数据'
							left: 'center',
							top: 'center'
						},
						xAxis: {
							name: data.unit.x,
							nameTextStyle: {
								color: '#D4D5D7'
							},
							data: data.xData,
							splitLine: {
								show: true,
								lineStyle: {
									color: '#384049',
									type: 'dashed'
								}
							},
							//x坐标轴轴线相关设置。
							axisLine: {
								show: true,
								lineStyle: {
									color: '#384049'
								},
								onZero: false
							},
							axisLabel: {
								// rotate: -30,
								interval: 'auto',
								color: '#D4D5D7'
							}
						},
						yAxis: {
							name: data.unit.y,
							nameTextStyle: {
								color: '#D4D5D7'
							},
							type: 'value',
							splitLine: {
								show: true,
								lineStyle: {
									color: '#384049',
									type: 'dashed'
								}
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: '#384049'
								},
								onZero: false
							},
							axisLabel: {
								// rotate: -30,
								interval: 'auto',
								color: '#D4D5D7'
							}
						},
						series: [],
						grid: {
							top: '12%',
							left: '7%',
							right: '10%',
							bottom: '20%',
							containLabel: true
						}
					};
					let yData = `yData1`;
					let yColor = `yColor1`;
					let yTitle = `yTitle1`;
					let yDataArr = data[yData];
					let yDataObj = {
						name: data[yTitle],
						type: 'line',
						smooth: true,
						data: yDataArr,
						itemStyle: {
								color: data[yColor],
								lineStyle: {
									color: data[yColor]
								}
							}
					};
					if (data.yMax1) {
						option.yAxis.max = data.yMax1;
					}
					let warnData = [];
					if (data.alarmShow == 1) {
						for (let j = 1; j <= 3; j++) {
							let warnColor = `alarmColor${j}`;
							let warnLine = `alarmValue${j}`;
							let warnName = `alarmName${j}`;
							warnData.push({
								name: data[warnName],
								yAxis: data[warnLine],
								lineStyle: {
									type: 'solid',
									color: data[warnColor]
								},
								label: {
									color: '#ffffff',
									formatter: params => {
										return params.name + ':' + params.value;
									}
								}
							});
							if (j == 3) {
								let max = parseFloat(data[warnLine]);
								for (let k = 0; k < data.yData1.length; k++) {
									let item = data.yData1[k];
									if (max < item) {
										max = item;
									}
								}
								option.yAxis.max = max;
							}
						}
						yDataObj.markLine = {
							data: warnData
						};
					}
					option.series.push(yDataObj);
				} else if (this.measType == 1004 || this.measType == 1003 || this.measType == 1002 || this.measType ==
					1001) {
					option = {
						tooltip: {
							confine: true, // 加入这一句话
							trigger: 'axis',
							backgroundColor: 'rgba(34, 34, 34, 0.5)',
							borderWidth: 0,
							color: '#ffffff',
							axisPointer: {
								animation: false
							},
							position: function(pos, params, el, elRect, size) {
								let obj = {
									top: 10
								};
								obj['left'] = pos[0] + 10;
								obj['top'] = pos[1] - 60;
								return obj;
							},
							formatter: param => {
								param = param[0];
								return [data.tooltip.x + '：' + param.name + '<br/>', data.tooltip.y + '：' + param
									.value + data.unit.y
								].join('');
							}
						},
						xAxis: {
							show: false,
							name: data.unit.x,
							nameTextStyle: {
								color: '#D4D5D7'
							},
							data: data.xData[0].data,
							// minInterval: 1,
							type: 'category',
							//x轴在 grid 区域中的分隔线。
							splitLine: {
								show: true,
								lineStyle: {
									color: '#384049',
									type: 'dashed'
								}
							},
							//x坐标轴轴线相关设置。
							axisLine: {
								show: true,
								lineStyle: {
									color: '#384049'
								},
								onZero: false
							},
							// 坐标轴刻度标签的相关设置。
							axisLabel: {
								// rotate: -30,
								interval: 'auto',
								color: '#D4D5D7'
							}
						},
						yAxis: {
							name: data.unit ? data.unit.y : '',
							nameTextStyle: {
								color: '#D4D5D7'
							},
							type: 'value',
							splitLine: {
								show: true,
								lineStyle: {
									color: '#384049',
									type: 'dashed'
								}
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: '#384049'
								},
								onZero: false
							},
							axisLabel: {
								color: '#D4D5D7'
							}
						},
						series: {
							data: data.yData[0].data,
							type: 'line',
							symbolSize: 5,
							itemStyle: {
								color: '#15B931'
							},
							lineStyle: {
								width: 0.8, // 0.1的线条是非常细的了
								color: '#15B931'
							},
							label: {
								show: true,
								position: 'top',
								color: '#15B931'
							}
						},
						dataZoom: [{
							show: false,
							realtime: true,
							start: 0,
							end: 16
						}],
						grid: {
							top: '12%',
							left: '7%',
							right: '10%',
							bottom: '20%',
							containLabel: true
						}
					};
					if (this.measType == 1002 || this.measType == 1004) {
						let warnData = [];
						let warnColor = `alarmColor`;
						let warnValue = `alarmValue`;
						let warnName = `alarmName`;
						let warnMax = `max`;
						let warnMin = `min`;
						if (data.yData[0].alarmShow == 1) {
							warnData.push({
								name: data.yData[0][warnName],
								yAxis: data.yData[0][warnValue],
								lineStyle: {
									type: 'solid',
									color: data.yData[0][warnColor]
								},
								label: {
									color: '#ffffff',
									formatter: params => {
										return params.name + ':' + params.value;
									}
								}
							});
							option.series.markLine = {
								data: warnData
							};
							option.yAxis.max = parseInt(data.yData[0][warnMax]);
							option.yAxis.min = parseInt(data.yData[0][warnMin]);
						}
					}
				}
				// 使用刚指定的配置项和数据显示图表。
				myChart8.clear();
				myChart8.setOption(option, true);
				window.addEventListener('resize', function() {
					myChart8.resize();
				});
				if (this.measType == 1002 || this.measType == 1001 || this.measType == 1004 || this.measType == 1003) {
					this.getMinuteTemp(myChart8, data);
				}
			},
			//温度转速图谱的动态滑动
			dynamicTupu(myChart, data) {
				if (this.tempInteval == null) {
					if (this.secondTempList.length == 0) {
						this.tempList = this.firstTempList;
					} else {
						this.tempList = this.secondTempList;
					}
					this.tempInteval = setInterval(() => {
						data.yData[0].data.shift();
						data.yData[0].data.push(this.tempList.yData[0].data[this.tempIndex]);
						data.xData[0].data.shift();
						data.xData[0].data.push(this.tempList.xData[0].data[this.tempIndex]);
						myChart.setOption({
							xAxis: {
								data: data.xData[0].data
							},
							series: {
								data: data.yData[0].data
							}
						});
						this.tempIndex++;
						if (this.tempIndex == 30) {
							this.getMinuteTemp(myChart, data);
						}
						if (this.tempIndex == 60) {
							clearInterval(this.tempInteval);
							this.tempInteval = null;
							this.tempIndex = 0;
							this.dynamicTupu(myChart, data);
						}
					}, 1000);
				}
			},
			getMinuteTemp(myChart, data) {
				let url = '';
				let param = {
					meas_id: this.measId,
					meas_def: this.measDef
				};
				if (this.measType == 1002 || this.measType == 1001) {
					url = '/backend-api/web/spec/temp/screen/minuter';
				} else if (this.measType == 1003) {
					url = '/backend-api/web/spec/oil/screen/minuter';
				} else if (this.measType == 1004) {
					url = '/backend-api/web/spec/rpm/screen/minuter';
					param = Object.assign(param, {
						type: this.winccType
					});
				}
				this.$get(url, param).then(res => {
					if (res.code == 1000) {
						if (this.firstTempList.length == 0) {
							this.firstTempList = res.data;
						} else if (this.secondTempList.length == 0) {
							this.secondTempList = res.data;
						} else {
							this.firstTempList = res.data;
							this.secondTempList = [];
						}
					}
					this.dynamicTupu(myChart, data);
				});
			}
		},
		destroyed() {
			if (measInteval) {
				clearInterval(measInteval);
			}
		}
	};
</script>
<style>
	.el-tabs__header {
		margin: 0;
	}

	#echarts_3>div:nth-child(1) {
		width: 100% !important;
	}

	#echarts_3 canvas {
		width: 100% !important;
	}
	
	.shopSignal{
		width: 20px;
		height: 20px;
		margin-right: 8px;
		cursor: pointer;
	}
	
</style>
<style lang="scss" scoped>
	@import '@style/jkdp/common.scss';
	@import '@style/jkdp/screen.scss';
	@import '@style/jkdp/three.scss';

	@import '@style/userCenter.scss';

	.frameChangeBtns {
		position: absolute;
		top: 122px;
		left: calc(50% - 268px);
	}
</style>